import React, { Component } from 'react';
import _ from 'lodash';

import { getContractorTandCs } from '../services/tandcs-service'
import { schema } from '../helpers/JoinUsValidation';
import Modal from './Modal';

class JoinUsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            companyName: '',
            fieldOfWork: '',
            areasCovered: '',
            gasSafeNumber: '',
            email: '',
            phone:'',
            emergencyCallOuts: false,
            termsAndConditions: false,
            gasSafeRegistered: false,
            cityAndGuildsQualified: false,
            NICEIC: false,
            availableAnytime: false,
            submitting: false,
            showTandCs: false,
            submitError: '',
            showThankYou: false,
            errors: []
        }
    }

    getJoinUsDetails = () => {
        return _.pick(this.state, ['name', 'email', 'phone', 'companyName', 'fieldOfWork',
            'areasCovered', 'emergencyCallOuts', 'termsAndConditions', 'gasSafeRegistered', 
            'cityAndGuildsQualified', 'NICEIC', 'availableAnytime']);
    }

    validateJoinUsDetails = () => {
        const row = this.getJoinUsDetails();
        this.setState({ errors: [] });
        return schema.validate(row, { abortEarly: false }).then((value) => {
            return true;
        })
        .catch((err) => {
            err.inner.forEach(element => {
                const error = this.state.errors;
                error[element.path] = element.message;
                this.setState({ errors: error }, () => { });
            });
            return false;
        });
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleFieldOfWorkSelect = (event) => {
        const value = event.target.value;
        this.setState({
            fieldOfWork: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const valid = await this.validateJoinUsDetails()
        if (valid) {
            try {
                this.setState({submitting: true});
                const row = this.getJoinUsDetails();
                let response = await fetch("/.netlify/functions/email", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        subject: `Request to join - ${row.name}, ${row.companyName}`,
                        details: row
                    })
                })                
                if(!response.ok) {
                    throw new Error('Error sending email');
                }
                this.setState({submitting: false, showThankYou: true})
            } catch (err) {
                this.setState({
                    submitting: false,
                    submitError: 'There was an error submitting your request. Please contact us.'
                });
            }
        }
    }

    render() {
        const { errors, showTandCs, submitError, showThankYou } = this.state;
        return (
            <div className="section">
                <div className="container">
                    {showThankYou ?
                    (<div className="content is-medium">
                        <h1>Thank you for your request</h1>
                        <p>We will review your details an get back to you as soon as possible.</p>
                    </div>):(
                    <div className="content is-medium">
                        <h2>Join Us</h2>
                        <p>
                            If you are a plumber or electrician and would like to join our team, fill in the form and we will get back to you as soon as possible
                        </p>
                    
                        <Modal show={showTandCs} content={getContractorTandCs()} title="Terms and Conditions" handleClose={() => this.setState({ showTandCs: false })} />
                        <form onSubmit={this.handleSubmit}>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="name" type="text" onChange={this.handleChange} placeholder="Enter your Full Name" />
                                </div>
                                {errors.name && <p className="help is-danger">{errors.name}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="companyName" type="text" onChange={this.handleChange} placeholder="Enter your Company Name" />
                                </div>
                                {errors.companyName && <p className="help is-danger">{errors.companyName}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="email" type="text" onChange={this.handleChange} placeholder="Email Address" />
                                </div>
                                {errors.email && <p className="help is-danger">{errors.email}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="phone" type="text" onChange={this.handleChange} placeholder="Phone number" />
                                </div>
                                {errors.phone && <p className="help is-danger">{errors.phone}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <div className="select" name="fieldOfWork" onChange={this.handleFieldOfWorkSelect} value={this.state.fieldOfWork || ''}>
                                        <select>
                                            <option key="0" value="">Please select your field of work</option>
                                            <option key="1" value="plumber">Plumber</option>
                                            <option key="2" value="electrician">Electrician</option>
                                        </select>
                                    </div>
                                </div>
                                {errors.fieldOfWork && <p className="help is-danger">{errors.fieldOfWork}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="areasCovered" type="text" onChange={this.handleChange} placeholder="Enter the areas you cover" />
                                </div>
                                {errors.areasCovered && <p className="help is-danger">{errors.areasCovered}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="emergencyCallOuts" /> Check this box if you are available for emergency call outs
                                    </label>
                                </div>
                                {errors.emergencyCallOuts && <p className="help is-danger">{errors.emergencyCallOuts}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="gasSafeRegistered" /> Check this box if you Gas Safe Registered
                                    </label>
                                </div>
                                {errors.gasSafeRegistered && <p className="help is-danger">{errors.gasSafeRegistered}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="cityAndGuildsQualified" /> Check this box if you are City and Guilds Qualified
                                    </label>
                                </div>
                                {errors.cityAndGuildsQualified && <p className="help is-danger">{errors.cityAndGuildsQualified}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="NICEIC" /> Check this box if you are NICEIC
                                    </label>
                                </div>
                                {errors.NICEIC && <p className="help is-danger">{errors.NICEIC}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="availableAnytime" /> Check this box if you are available anytime (not only 6am to 6pm)
                                    </label>
                                </div>
                                {errors.availableAnytime && <p className="help is-danger">{errors.availableAnytime}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={this.handleChange} name="termsAndConditions" /> By clicking here you agree to the
                                    </label>
                                    <a onClick={() => { this.setState({ showTandCs: true }) }}> terms and conditions</a>
                                    {errors.termsAndConditions && <p className="help is-danger">{errors.termsAndConditions}</p>}
                                </div>
                            </div>
                            {submitError && <p className="help is-danger">{submitError}</p>}
                            <div className="field is-grouped is-grouped-right">
                                <div className="control">
                                    <button type="submit" className={"button is-primary " + (this.state.submitting ? 'is-loading' : '')} disabled={this.state.submitting}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>)}
                </div>
            </div>
        );
    }
}

export default JoinUsPage;