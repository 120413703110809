import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Helmet from 'react-helmet'

import Tile from './Tile';
import {slugify} from '../util';

class ButtonPage extends Component {
  constructor(props) {
    super(props);
    this.state={
      buttons:[{id:0, name:"loading"}],
      linkText: '',
      title: ''
    }
  }

  componentDidMount(){
    this.setState({
      buttons:this.props.buttons,
      linkText: this.props.linkText,
      title: this.props.title
    })
   
  }

  renderRedirect = () => {
    if(this.props.match.params.help){
      if(this.props.buttons.length === 1){
        return <Redirect to={this.props.linkText+"/"+slugify(this.props.buttons[0].name)} />
      }
    }
  }

  createMarkup = () => {
    return {__html: this.props.description};
  }
  
  render() {
    return (
      <Fragment>
        {this.renderRedirect()}
      <Helmet>
        <title>{"Quickcalls | "+this.state.title || "Quickcalls"}</title>
        <meta property="og:title" content={"Quickcalls | "+this.state.title || "Quickcalls"} />
        <meta property="og:description" name="description" content={this.props.description ? this.props.description.replace(/<[^>]*>?/gm, '') : "Book plumbers and electricians all over the UK. We specialise in the South Wales area including Cardiff, Caerphilly, Nantgarw, Treforest and Barry"} />
      </Helmet>
      <section className="section">  
        <div className="container">
          <h1 className={"title is-1 " + (this.props.centre && 'has-text-centered')}>{this.state.title}</h1>
          <div className={"columns is-multiline " + (this.props.centre &&  'is-centered')}>
            {this.state.buttons.map((button)=>{
              const buttonName = slugify(button.name);
              return(                
              <Link key={button.id} to={`${this.state.linkText}/${buttonName}`}>
                <Tile key={button.id} title={button.name} className={button.className} faIcon={button.faIcon}/>
              </Link>
              )
            })}
          </div>
        </div>
      </section>
      {this.props.description && <section className="section has-background-white-ter">
          <div className="container">
            <div className="content">
              <div dangerouslySetInnerHTML={this.createMarkup()}></div>
            </div>
          </div>
      </section>}
      </Fragment>
    );
  }
}

export default ButtonPage;
