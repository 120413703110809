import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarActive: false
        }
    }

    handleMenuClick = () => {
        this.setState(prevState => ({
            navbarActive: !prevState.navbarActive
        }));
    }

    handleMenuItemClick = () => {
        this.setState({
            navbarActive: false
        });
    }
    render() {
        return (<>
            <Helmet>
                <title>Quickcalls</title>
                <meta property="og:title" content="Quickcalls" />
                <meta property="og:description" name="description" content="Book plumbers and electricians all over the UK. We specialise in the South Wales area including Cardiff, Caerphilly, Nantgarw, Treforest and Barry" />
            </Helmet>
            <nav className="navbar is-light" role="navigation" aria-label="main navigation">
                <div className="container">
                <div className="navbar-brand">
                    <Link to="/" onClick={this.handleMenuItemClick} className="navbar-item">
                        <img src={require('../images/quick-calls-logo-text.png')} alt="Quickcalls Logo" />
                    </Link>

                    <button className={"header-button-nav navbar-burger burger " + (this.state.navbarActive ? 'is-active' : '')} onClick={this.handleMenuClick} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </button>
                </div>

                <div id="navbarBasicExample" className={"navbar-menu " + (this.state.navbarActive ? 'is-active' : '')}>
                    <div className="navbar-start">
                        <Link to="/" onClick={this.handleMenuItemClick} className="navbar-item">Home</Link>
                        <Link to="/about" onClick={this.handleMenuItemClick} className="navbar-item">About</Link>
                        <Link to="/price-list" onClick={this.handleMenuItemClick} className="navbar-item">Price List</Link>
                        <Link to="/contact" onClick={this.handleMenuItemClick} className="navbar-item">Contact</Link>
                        <Link to="/join-us" onClick={this.handleMenuItemClick} className="navbar-item">Join Us</Link>
                    </div>
                    <div className="navbar-end">
                        <div className="navbar-item">
                            tel:
                            <span itemProp="telephone">
                                <a href="tel:08006403132">0800-640-3132</a>
                            </span>
                        </div>
                    </div>
                </div>
                </div>
            </nav>
        </>
        )
    }
}

export default Header;