import React from 'react';

function Modal({ handleClose, show, content, title }) {
    const showHideClassName = show ? "modal is-active" : "modal";
    return <div className={showHideClassName}>
        <div className="modal-background" onClick={handleClose}></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button onClick={handleClose} className="delete" aria-label="close"></button>
            </header>
            <section className="modal-card-body">
                <div class="content" dangerouslySetInnerHTML={{__html: content}}>
                </div>
                
            </section>
        </div>
    </div>
}

export default Modal;