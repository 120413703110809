import React from 'react';
import Helmet from 'react-helmet'

function PriceListPage() {
    return <section className="section">
        <Helmet>
            <title>Quickcalls | Price List</title>
            <meta property="og:title" content="Quickcalls Price List" />
            <meta property="og:description" name="description" content="Price List for Quickcalls" />
        </Helmet>
        <div className="container">
            <div className="content">
                <p>We charge a sourcing fee which is payable upon submission of your work order, this fee is non refundable and paid directly to Quickcalls.</p>
                <p>A call out and first hour labour charge is agreed upon confirmation of the work order and this is payable directly to the attending engineer, any materials that may be required are additional as are any further hours of works that may be required. </p>
                <p>Any further works required are to be discussed between the contractor and client only and will not involve Quickcalls unless submitted via the proper channels. Payments made to contactors can be made via Cash, bank transfer or Debit Card.</p>

                <h1>Price List</h1>
                <table className="table is-striped">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Call out Charge</th>
                            <th>First hour labour</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>6am - 6pm</td>
                            <td>£60</td>
                            <td>£90</td>
                        </tr>
                        <tr>
                            <td>6pm - 6am</td>
                            <td>£100</td>
                            <td>£160</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
}

export default PriceListPage;