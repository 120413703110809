const {slugify} = require('../util');

const data = [
    {
        id: 0,
        name: "Plumber",
        className: "is-info",
        faIcon: "tint",
        description: "<p>Good plumbers are hard to find, we source the best plumbers out there to complete your job.</p><p>Plumbers can repair your Boiler/Central Heating, Gas and various other items. Not only do we specialise in plumbers in the South Wales in particular Cardiff, but also all over the UK.</p>",
        help: [
            {
                id: 0,
                name:"Boiler/Central Heating",
                className: "is-info",
                description: "<p>Issues with your Boiler and Central Heating systems should be immediately looked at by a professional. If left over time it can cause serious damage.</p>",
                thats:[
                    {
                        id: 0,
                        name:"Leaking",
                        className: "is-info",
                        title: "Plumber to help with my Boiler/Central Heating that's leaking",
                    },
                    {
                        id: 1,
                        name:"Making Noise",
                        className: "is-info",
                        title: "Plumber to help with my Boiler/Central Heating that's Making a Noise"
                    },
                    {
                        id: 2,
                        name:"Not Working",
                        className: "is-info",
                        title: "Plumber to help with my Boiler/Central Heating that's Not Working"
                    },
                    {
                        id: 3,
                        name:"Other",
                        className: "is-info",
                        title: "Plumber to help with my Boiler/Central Heating"
                    },
                ]
            },
            {
                id: 1,
                name:"Gas",
                description: "<p>Issues with your <strong>Gas</strong> can be very serious. Almost 5 million households are at risk from unsafe gas work. If you are worried about your gas supply, get it looked at immediately by a gas engineer. We will source you a gas engineer to come take a look at the problem. We operate all over the UK (if it is in the Cardiff area we can get to you very quickly).</p>",
                className: "is-info",
                thats:[
                    {
                        id: 0,
                        name:"No Gas",
                        className: "is-info",
                        title: "Plumber to help with my Gas"
                    },
                    {
                        id: 1,
                        name:"Gas Leak",
                        className: "is-info",
                        title: "Plumber to help with my Gas Leak"
                    },
                    {
                        id: 3,
                        name:"Other",
                        className: "is-info",
                        title: "Plumber to help with my Gas"
                    },
                ]
            },
            {
                id: 2,
                name:"Bathroom",
                className: "is-info",
                description: "<p>You need your bathroom always to be in working order. If your taps are leaking or your shower broken. Complete this form and we'll source an engineer for you as soon as possible, even if it is an emergency.</p>",
                thats:[
                    {
                        id: 0,
                        name:"Blockage",
                        className: "is-info",
                        title: "Plumber to help with my Bathroom with a blockage"
                    },
                    {
                        id: 1,
                        name:"Shower",
                        className: "is-info",
                        title: "Plumber to help with my Shower in the Bathroom"
                    },
                    {
                        id: 2,
                        name:"Taps",
                        className: "is-info",
                        title: "Plumber to help with my Taps in the Bathroom"
                    },
                    {
                        id: 3,
                        name:"Water Leak",
                        className: "is-info",
                        title: "Plumber to help with a water leak in my Bathroom"
                    },
                    {
                        id: 4,
                        name:"Other",
                        className: "is-info",
                        title: "Plumber to help with my Bathroom"
                    },
                ]
            },
            {
                id: 3,
                name:"Pipes",
                className: "is-info",
                description:"Issues with your pipes should be immediately looked at by a qualified plumber. The problem could get worse and cause serious damage to your property.",
                thats:[
                    {
                        id: 0,
                        name:"Leaking Pipe",
                        className: "is-info",
                        title: "Plumber to help with my Pipe that's Leaking"
                    },
                    {
                        id: 1,
                        name:"Damaged Pipe",
                        className: "is-info",
                        title: "Plumber to help with my Pipe that's Damaged"
                    },
                    {
                        id: 2,
                        name:"Blocked Pipe",
                        className: "is-info",
                        title: "Plumber to help with my Pipe that's Blocked"
                    },
                    {
                        id: 3,
                        name:"Other",
                        className: "is-info",
                        title: "Plumber to help with my Pipe"
                    },
                ]
            },
            {
                id: 4,
                name:"Other",
                className: "is-info",
                description: "If you need a plumber for something else, let us know, put as much detail as you can in the additional information section.",
                thats:[
                    {
                        id: 0,
                        name:"Other",
                        className: "is-info",
                        title: "Plumber to help with Other"
                    }
                ]
            }
        ]
    },
    {
        id: 1,
        name: "Electrician",
        className: "is-danger",
        faIcon: "bolt",
        description: "<p>Anything to do with electricity should be handled by a professional. We will source you the nearest electrician in your area.</p>",
        help:[
            {
                id: 0,
                name: "Fuse Box",
                className: "is-danger",
                thats:[
                    {
                        id: 0,
                        name: "Tripping",
                        className: "is-danger",
                        title: "Electrician to help with a Fuesbox that's Tripping"
                    },
                    {
                        id: 1,
                        name: "Not Working",
                        className: "is-danger",
                        title: "Electrician to help with a Fuesbox that's Not Working"
                    },
                    {
                        id: 2,
                        name: "Other",
                        className: "is-danger",
                        title: "Electrician to help with a Fuesbox"
                    }
                ]
            },
            {
                id: 1,
                name: "Lights",
                className: "is-danger",
                thats:[
                    {
                        id: 0,
                        name: "Light Not Working",
                        className: "is-danger",
                        title: "Electrician to help with Lights that's Not Working"
                    },
                    {
                        id: 1,
                        name: "Light Damaged",
                        className: "is-danger",
                        title: "Electrician to help with Lights that's Damaged"
                    },
                    {
                        id: 2,
                        name: "Other",
                        className: "is-danger",
                        title: "Electrician to help with Lights"
                    }
                ]
            },            
            {
                id: 2,
                name: "Sockets",
                className: "is-danger",
                thats:[
                    {
                        id: 0,
                        name: "Socket Not Working",
                        className: "is-danger",
                        title: "Electrician to help with Sockets that's Not Working"
                    },
                    {
                        id: 1,
                        name: "Damaged Socket",
                        className: "is-danger",
                        title: "Electrician to help with Sockets that's Damaged"
                    },
                    {
                        id: 2,
                        name: "Other",
                        className: "is-danger",
                        title: "Electrician to help with Sockets"
                    }
                ]
            },
            {
                id: 3,
                name: "Cables",
                className: "is-danger",
                thats:[
                    {
                        id: 0,
                        name: "Not Working",
                        className: "is-danger",
                        title: "Electrician to help with Cables that's Not Working"
                    },
                    {
                        id: 1,
                        name: "Unsafe",
                        className: "is-danger",
                        title: "Electrician to help with Cables that's Unsafe"
                    },
                    {
                        id: 2,
                        name: "Other",
                        className: "is-danger",
                        title: "Electrician to help with Cables"
                    }
                ]
            },
            {
                id: 4,
                name: "Other",
                className: "is-danger",
                thats:[
                    {
                        id: 0,
                        name: "Other",
                        className: "is-danger",
                        title: "Electrician to help with Other"
                    }
                ]
            }
        ]
    }
];

const getTrades = () => {
    return data;
}

const getHelpWithByTrade = (name) => {
    return data.find((d)=>slugify(d.name)===slugify(name));
}

const getThatsByTradeAndHelpWith = (trade, helpWith) => {
    return getHelpWithByTrade(trade).help.find((h)=>slugify(h.name)===slugify(helpWith));
}

const getTitleByTradeHelpWithAndThats = (tradeInput, helpInput, thatsInput) => {
    const trade = data.find(d => slugify(d.name) === slugify(tradeInput));
    const help = helpInput ? trade.help.find(h => slugify(h.name) === slugify(helpInput)) : '';
    const thats = thatsInput ? help.thats.find(t => slugify(t.name) === slugify(thatsInput)) : '';

    return {
        trade : trade? trade.name: '',
        help: help ? help.name : '',
        thats: thats ? thats.name : ''}
}

const getThatsByTradeHelpWithAndThats = (tradeInput, helpInput, thatsInput) => {
    const trade = data.find(d => slugify(d.name) === slugify(tradeInput));
    const help = trade.help.find(h => slugify(h.name) === slugify(helpInput));
    const thats = help.thats.find(t => slugify(t.name) === slugify(thatsInput));

    return thats;
}

const getFullTitleByTradeHelpWithAndThats = (tradeInput, helpInput, thatsInput) => {
    return getThatsByTradeHelpWithAndThats(tradeInput, helpInput, thatsInput).title
}

const getUrls = () => {
    let urlList = [];
    for(let i=0; i<data.length; i++){
        const trade='/'+slugify(data[i].name)
        urlList.push(trade);
        for(let j=0; j<data[i].help.length; j++) {
            const help='/'+slugify(data[i].help[j].name);
            urlList.push(trade+help);
            for(let k=0; k<data[i].help[j].thats.length; k++){
                const thats='/'+slugify(data[i].help[j].thats[k].name);
                urlList.push(trade+help+thats);
            }
        }
    }
    return urlList;
}

module.exports = {getTrades, getHelpWithByTrade, getThatsByTradeAndHelpWith, getTitleByTradeHelpWithAndThats,
    getFullTitleByTradeHelpWithAndThats, getUrls}