import React, { Component } from 'react';

export class ThankYouForm extends Component {
    render() {
        return (
            <section className="section">
                <div className="container">
                    <div className="content is-medium">
                        <h1>Thank you very much for your purchase</h1>
                        <p>An email has been sent to {this.props.location.state.email}. Please check your junk folder as well</p>
                        <p>We will aim to process your request within 30 minutes. If booking between midnight and six please give us a ring</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default ThankYouForm;