import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ content, title }) {
    return <footer className="footer">
        <div className="content has-text-centered">
            <Link to="/terms-and-conditions" >Terms and Conditions </Link>
            |
            <Link to="/contractor-terms-and-conditions"> Contractor Terms and Conditions </Link>
        </div>
    </footer>
}

export default Footer;