import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import ReactGA from 'react-ga';

export const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding,
            },
            invalid: {
                color: '#ff3860',
            },
        },
    };
};

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            cardError: '',
            timeEnteredPage: new Date(),
            errors: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getUserDetails = () => {
        return _.pick(this.props, ['name', 'email', 'phone', 'additionalInfo', 'addressLine1',
            'addressLine2', 'addressCity', 'addressPostcode']);
    }

    handleSubmitCharge = async (event) => {
        event.preventDefault();
        this.setState({ submitting: true, cardError: '' });
        const row = this.getUserDetails();
        let { token } = await this.props.stripe.createToken({
            name: row.name,
            address_line1: row.addressLine1,
            address_line2: row.addressLine2,
            address_city: row.addressCity,
            address_zip: row.addressPostcode,
            address_country: 'GB'
        });
        if (!token) {
            this.setState({
                cardError: 'There is an error with your payment details',
                submitting: false
            }, () => { });
            return;
        }

        try {
            let response = await fetch("/.netlify/functions/charge", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: token,
                    timeEnteredPage: this.state.timeEnteredPage,
                    title: `${this.props.fullTitle}`,
                    details: row
                })
            });
            if (response.ok) {
                this.setState({ submitting: false })
                ReactGA.event({
                    category: 'Payment',
                    action: 'Payment Made'
                });
                this.props.onSubmit();
            } else {
                this.setState({
                    cardError: 'There was an error processing your payment, please contact us for further details',
                    submitting: false
                }, () => { });
                ReactGA.event({
                    category: 'Payment',
                    action: 'Payment Failed'
                });
            }            
        } catch (err) {
            this.setState({
                cardError: 'There was an error processing your payment, please contact us for further details',
                submitting: false
            }, () => { });
            ReactGA.event({
                category: 'Payment',
                action: 'Payment Failed for unknown reasons'
            });
        }
    }

    getPrice() {
        const t = this.state.timeEnteredPage.getHours();
        if( t >= 18 || t < 6) {
            return {
                callOut:"£100",
                firstHourLabour:"£160",
            }
        } else {
            return {
                callOut:"£60",
                firstHourLabour:"£90",
            }
        }
    }

    getTime() {
        return this.state.timeEnteredPage.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    render() {
        return (
            <Fragment>                
            <div className="content">
                <h1>Payment</h1>
                <p>The time is now {this.getTime()}. You will now be charged <strong>{this.getPrice().callOut}</strong>. If we fail to confirm your booking within 30 minutes, we will refund you the full amount. The tradesman will charge {this.getPrice().firstHourLabour} for the first hour.</p>
                <p>Please enter your card details below. Payments are handled using <a href="https://stripe.com" target="_blank" rel="noopener noreferrer"> Stripe</a>.</p>
            </div>
            <form onSubmit={this.handleSubmitCharge}>
                {this.state.cardError && <p className="help is-danger">{this.state.cardError}</p>}
                <div className="field">
                    <label className="label">Card number </label>
                    <div className="control">
                        <CardNumberElement {...createOptions(this.props.fontSize)} className="card-element"/>
                    </div>
                </div>
                <div className="field is-horizontal">
                <div className="field-body">
                <div className="field">
                    <label className="label">Card Expiry</label>
                    <div className="control is-expanded">
                        <CardExpiryElement {...createOptions(this.props.fontSize)} className="card-element"/>
                    </div>
                </div>
                
                <div className="field">
                    <label className="label">CVC</label>
                    <div className="control is-expanded">
                        <CardCVCElement {...createOptions(this.props.fontSize)} className="card-element"/>
                    </div>
                </div>
                </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                    <div className="control">
                        <button type="submit" className={"button is-primary " + (this.state.submitting ? 'is-loading' : '')} disabled={this.state.submitting}>Submit</button>
                    </div>
                </div>
            </form>
            </Fragment>
        )
    }
}

export default injectStripe(PaymentForm)