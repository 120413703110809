import React from 'react';
function AboutPage() {
    return <section className="section">
        <div className="container">
            <div className="content">
                <h1>About Us</h1>
                <p>Quickcalls is a engineer sourcing company for both emergency jobs and normal repair works. With a vast database of plumbers, gas engineers and electricians covering the majority of the UK we are the one call you need to make to get a quick response. </p>
                <p>We only source engineers who are qualified and hold recognisable qualification and certification. We make every attempt to work with only the most recommended and highly rated engineers , this way sourcing the best of the best workmen for you to ensure you get the best service possible. A leak, power cut or another issue which may require urgent attention can cause extreme worry and stress, by using Quickcalls you can ensure that you get a great engineer who will give you a fixed window of arrival and an agreed price prior to their arrival. This will ensure you do not get over charged which unfortunately we find happens a lot of times in these situations.</p>
                <p>Quickcalls will prepare a full work order for the engineer with as much detail as possible to prepare the engineer prior to arrival. In the unfortunate event of an emergency simply give us a call or submit your requirements via our simple website form, Once we receive the job details we start working on sourcing the best local engineer for you, once the payment is complete we confirm your allocated engineer details to you via text along with an ETA. </p>
                <p>We specialise in the South Wales area including Cardiff, Caerphilly, Nantgarw, Treforest, Bridgend, Newport, Swansea and Barry.</p>
            </div>
        </div>
    </section>
}

export default AboutPage;