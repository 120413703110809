import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class StepHelp extends Component {
    render(){
        return(
            <div className="column">
                <article className="card">
                    <div className="card-content">
                    <h2 className="subtitle has-text-centered">{this.props.title}</h2>
                    <p className="subtitle has-text-centered">{this.props.subtitle}</p>
                    <div className="level">
                        <div className="level-item has-text-centered">
                            <FontAwesomeIcon className="has-text-centered" icon={this.props.icon} size="6x"/>
                        </div>
                    </div>
                    <p>{this.props.description}</p>
                    </div>
                </article>
            </div>
        );
    }
}

export default StepHelp;