import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom'
import ReactGA from 'react-ga';


import { getHelpWithByTrade, getThatsByTradeAndHelpWith, getTitleByTradeHelpWithAndThats } from './services/services';
import {getTandCs, getContractorTandCs}  from './services/tandcs-service'

import ButtonPage from './components/ButtonPage';
import FormPage from './components/FormPage';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ThankYouForm from './components/ThankYouForm';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import ErrorBoundary from './components/ErrorBoundary';
import PriceListPage from './components/PriceListPage';
import GenericPage from './components/GenericPage';
import Footer from './components/Footer';
import JoinUsPage from './components/JoinUsPage';

class App extends Component {

  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <ErrorBoundary>
          <Header />
          <Switch>
            <Route exact path="/" key="0" render={(props) => <HomePage {...props} />} />
            <Route exact path="/about" key="1" render={(props) => <AboutPage />} />
            <Route exact path="/contact" key="2" render={(props) => <ContactPage />} />
            <Route exact path="/thank-you" key="3" render={(props) => <ThankYouForm {...props} />} />
            <Route exact path="/price-list" key="4" render={(props) => <PriceListPage {...props} />} />
            <Route exact path="/join-us" key="4" component={JoinUsPage} />
            <Route exact path="/terms-and-conditions" key="4" render={(props) => <GenericPage title="Terms and Conditions" content={getTandCs()} {...props} />} />
            <Route exact path="/contractor-terms-and-conditions" key="4" render={(props) => <GenericPage title="Contractor Terms and Conditions" content={getContractorTandCs()} {...props} />} />

            <Route exact path="/:trade" key="5" render={(props) =>
              <ButtonPage  {...props}
                buttons={getHelpWithByTrade(props.match.params.trade).help}
                title={getTitleByTradeHelpWithAndThats(props.match.params.trade).trade + ' to help with ...'}
                linkText={'/' + props.match.params.trade}
                description={getHelpWithByTrade(props.match.params.trade).description}
              />} />

            <Route exact path="/:trade/:help" key="6" render={(props) =>
              <ButtonPage {...props}
                title={getTitleByTradeHelpWithAndThats(props.match.params.trade, props.match.params.help).help + ' ...'}
                buttons={getThatsByTradeAndHelpWith(props.match.params.trade, props.match.params.help).thats}
                linkText={'/' + props.match.params.trade + '/' + props.match.params.help}
                description={getThatsByTradeAndHelpWith(props.match.params.trade, props.match.params.help).description}
              />}
            />

            <Route exact path="/:trade/:help/:thats" key="7" component={FormPage} />




          </Switch>
          <Footer />
        </ErrorBoundary>
      </Fragment>
    );
  }
}

export default App;