import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Fragment>
                    <Header />
                    <section className="section">
                        <div className="container">
                            <div className="content">
                                <h1>Something went wrong.</h1>
                                <p>Click <Link to="/" >here</Link> to go back to the hompage</p>
                            </div>
                        </div>
                    </section>
                </Fragment>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;