import * as Yup from 'yup';

export const schema = Yup.object().shape({
    name: Yup.string().required('Your full name is required'),
    email: Yup.string()
        .email()
        .required('Your email address is required'),
    phone: Yup.number()
        .typeError('Your phone number must only contain numbers')
        .required('A phone number is required'),
    companyName: Yup.string()        
        .required('Please enter your company name'),
    fieldOfWork: Yup.string()        
        .required('Please select your field of work'),
    areasCovered: Yup.string()        
        .required('Please enter the areas you cover'),
    
    termsAndConditions: Yup.boolean().oneOf([true], 'Please accept the terms and conditions before proceeding') ,
})

