import * as Yup from 'yup';

export const schema = Yup.object().shape({
    name: Yup.string().required('Your full name is required'),
    email: Yup.string()
        .email()
        .required('Your email address is required'),
    phone: Yup.number()
        .typeError('Your phone number must only contain numbers')
        .required('A phone number is required'),
    additionalInfo: Yup.string()
        .min(8, 'Please enter more than 8 characters')
        .required('Please enter some additional information about the problem'),
    addressLine1: Yup.string().required(),
    addressCity: Yup.string().required(),
    addressPostcode: Yup.string().required(),
    termsAndConditions: Yup.boolean().oneOf([true], 'Please accept the terms and conditions before proceeding') ,
})

