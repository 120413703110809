import React from 'react';

function GenericPage({ content, title }) {
    return <section className="section">
        <div className="container">
            <h1 className="title">{title}</h1>
            <div className="content" dangerouslySetInnerHTML={{__html: content}} ></div>
        </div>
    </section>
}

export default GenericPage;