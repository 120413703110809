import React, { Component } from 'react';
import Helmet from 'react-helmet'

import ButtonPage from './ButtonPage';
import {getTrades} from '../services/services';
import StepHelp from './StepHelp';

class HomePage extends Component {
    getButtons = () => {
        return getTrades();
    }

    getTitle = () => {
        return 'Find me a ...';
    }
    render() {
        return (<>
            <ButtonPage centre="true" {...this.props} buttons={this.getButtons()} title={this.getTitle()} getData={getTrades} linkText=''/>
            <Helmet>
                <title>Quickcalls</title>
                <meta property="og:title" content="Quickcalls" />
                <meta property="og:description" name="description" content="Book plumbers and electricians all over the UK. We specialise in the South Wales area including Cardiff, Caerphilly, Nantgarw, Treforest and Barry"/>
            </Helmet>
			<div className="level">
                <p class="level-item has-text-centered">
                    <a class="button is-medium is-primary" href="tel:08006403132">Call Now</a>
                </p>
            </div>
			<section className="section has-background-white-ter">
                <div className="container">
                    <h1 className="title is-2">How it works?</h1>
                    <div className="columns">
                    <StepHelp title="Step 1" subtitle="Tell us what you need" icon="user-tie" description="Describe the job requirements through our online form." />
                    <StepHelp title="Step 2" subtitle="Complete your booking" icon="credit-card" description="Complete the booking. Our team will start allocating your job immediately." />
                    <StepHelp title="Step 3" subtitle="Confirmation" icon="hands-helping" description="You will receive a call confirming your booking" />
                    </div>
                </div>
            </section>
            </>        
        )};
}

export default HomePage;