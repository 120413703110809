import React, { Component } from 'react';
import { getFullTitleByTradeHelpWithAndThats } from '../services/services';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom'

import UserForm from './UserForm';
import PaymentForm from './PaymentForm';

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      title: '',
      name: '',
      email: '',
      phone: '',
      additionalInfo: '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressPostcode: '',
    }
  }

  componentDidMount() {
    const { trade, help, thats } = this.props.match.params;
    this.setState({
      fullTitle:getFullTitleByTradeHelpWithAndThats(trade, help, thats)
    });
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  }

  handleSubmitUserDetails = (values) => {
    this.setState({
      ...values
    });
    this.nextStep();
  }

  handleSumbitCharge = () => {
    this.nextStep();
  }

  showStep = () => {
    

    switch (this.state.step) {
      case 1:
        return <UserForm title={this.state.fullTitle} onSubmit={this.handleSubmitUserDetails} />
      case 2:
        return <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <Elements>
            <PaymentForm {...this.state} onSubmit={this.handleSumbitCharge}/>
          </Elements>
        </StripeProvider>
      case 3:
        return <Redirect to={{
          pathname: '/thank-you',
          state: {email: this.state.email}
        }} />        
      default:
        return <div></div>
    }
  }
  render() {
    return(
      <div className="section">
        <div className="container">
          {this.showStep()}
        </div>
      </div>
    )
    
  }
}

export default FormPage;
