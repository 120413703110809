import React, { Component } from 'react';

class PostcodeLookup extends Component {
    constructor(props) {
        super(props);
        this.state={
            houseNumberSearch: '',
            postcodeSearch: ''
        }
    }

    handlePostcodeLookUp = () => {
        const {houseNumberSearch, postcodeSearch} = this.state;
        
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${houseNumberSearch} ${postcodeSearch}&key=AIzaSyCCQxEFb5CJ4xMU86IbnYdovmph6_wbviY`)      
        .then(response => response.json())
        .then((data) => {
            const address= data.results[0].address_components
            const street = address[1].long_name;
            const city = address[2].long_name;
            this.props.onPostcodeLookup({addressLine1: houseNumberSearch+' '+street, city, postcode: postcodeSearch})
        }).catch((err) => {
            console.log(err);
            this.props.onPostcodeLookup({addressLine1: '', city:'', postcode: ''})
        });
  
    }

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });
    }

    render() {
        return (
            <div className="field is-horizontal">
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" type="text" name="houseNumberSearch" onChange={this.handleInputChange} placeholder="Enter House Number/Name" />
                    </div>
                    <div className="control is-expanded">
                        <input className="input" type="text" name="postcodeSearch" onChange={this.handleInputChange} placeholder="Enter Postcode" />
                    </div>
                    <div className="control">
                        <button onClick={this.handlePostcodeLookUp} type="button" className="button is-warning">Lookup</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostcodeLookup;