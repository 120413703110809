import React from 'react';
function ContactPage() {
    return <section className="section">
        <div className="container">
            <div className="content">
                <h1>Contact Us</h1>
                <p>If you have any queries, please contact us on 08006403132</p>
            </div>
        </div>
    </section>
}

export default ContactPage;