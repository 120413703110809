const tandcs = `
<style>
ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    font-weight: bold;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
  }
  
  li ol > li {
    font-weight: normal;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
  }
</style>
<ol class="is-marginless">
    <li>
        PAYMENTS
        <ol>
            <li>The Person paying for the Services provided by Quickcalls and the assigned engineer must be at least 18 years of age</li>
            <li>The deposit paid upon booking the work order is non refundable for all emergency call outs. Deposits paid on non emergency jobs shall be refundable provided cancellation is received 24 hours prior.</li>
            <li>Labour and 1st hour charge is payable directly the attending engineer. 1st hour begins from the moment the engineer arrives at the premises, time arrival may be confirmed by engineer or customer with quickcalls, if not confirmed evidence will be required should a dispute arise.</li>
            <li>A cooling off period will be in place for non-emergency jobs only and cancellation is only accepted with at least 24 hours notice.</li>
            <li>Payments online to Quickcalls are secure and managed by Stripe as are payments made over the phone</li>
            <li>Customers should complete the agreed first payment to the engineer upon completion of the first visit unless otherwise agreed with the engineer.</li>
            <li>The attending engineer may in some circumsrances agreed to accept payment within 7 days of the first visit, client will be invoiced by the engineer directly and is liable to complete payment within the agreed timeframe.</li>
        </ol>    
    </li>
   

    <li>
        WORK, DELIVERY, POLICY & NOTICE
        <ol>
            <li>Quickcalls will request Contractors to attend at a Client’s premises within 2 hours of a booking being made and Deposit received. In the event that a Contractor is unable to comply with this timeframe they will provide the Client with notice of a revised ETA and a 30 minute allowance will be given.</li>
            <li>If the Contractor arrives before the appointment time and the Client is not at the premises then the Contractor cannot charge for any waiting time that results. Once the appointment time has passed the Contractor can charge once the Client has failed to arrive at the premises for 1 hour.</li>
            <li>If the Client is unable to attend the premises at the appointment time then the Client must let the Contractor or Quickcalls know by e-mail or by replying to the text message received advising of the appointment time. The deposit will be non refundable and the contractors call out may still be payable</li>
            <li>If the Contractor attends the premises where the Services are to be provided at the appointment time and the Client fails to allow entry for 1 hour an invoice for 1 hours’ labour will apply and must be paid within 7 days.</li>
            <li>If the premises have no number then an additional 10 minutes allowance will be given to enable the Contractor to locate the address and in the event that the premises are more than 500 metres from the address given then a reasonable time allowance will be applied depending on the circumstances of a particular booking.</li>
            <li>If Quickcalls is given an incorrect address the Client may lose their call-out deposit fee and a further Deposit may need to be paid subject to the discretion of the Contractor.</li>
            <li>For non-emergency work 24 hours’ notice needs to be given and a Deposit paid. Non-emergency work will be carried out between Mondays and Fridays from 8:00 a.m to 4:00 p.m on an hourly rate. Free estimates or quotations can be provided via e-mail subject to sufficient description being provided via e-mail at Quickcalls discretion.</li>
            <li>It is the Client’s responsibility to contact any Contractor who fails to attend a premises where Services have been booked and it is not the responsibility of Quickcalls to check that the Contractor has arrived.</li>
        </ol>
    </li>
    
    <li>
        DISCLAIMER
        <ol>
            <li>Although we make an effort to ensure Contractors whose details we provide are legitimate, reputable and have appropriate qualifications, membership to and/or approval of trade related bodies organisations and associations and that the information we had is accurate, the information is compiled from details supplied to us from the Contractors and we cannot be held responsible for any errors or inaccuracies in such information or for the suitability or quality of any services or goods supplied to Clients through our Service.</li>
            <li>The provision of a Contractor’s details does not necessarily imply our approval of the tradesmen, suppliers or companies.</li>
            <li>We accept no liability for any transactions which take place between you and Contractor whose details we have provided.</li>
        </ol>
    </li>
    
    <li>
        Cancellations, refunds & notices
        <ol>
            <li>A Client can cancel a booking by e-mail or by replying to a text received from Quickcalls  confirming the terms of a booking. Email will only be accepted from the address given upon booking the job</li>
            <li>For non-emergency bookings a cancellation by a Client can be made up to 24 hours before the Services are to be provided.</li>
            <li>Nova Lettings is not obliged to refund any monies paid by a Client prior to a booking being cancelled under condition 4.1 or 4.2 above.</li>
            <li>Quickcalls can cancel a booking if it deems it reasonable to do so and will refund in full the Deposit in such circumstances.</li>
            <li>Any refunds will be made by the same method as payment was made originally or by such other alternative method as is agreed with the Client.</li>
            <li>In circumstances where the Contractor is en route to the Client’s premises when a cancellation is effected an inconvenience fee of £30.00 may at Quickcalls discretion be paid to the Contractor from the call out fee paid by the Client.</li>
        </ol>
    </li>
    
    <li>
        CONTRACTS, DUTIES & RESPONSIBILITIES
        <ol>
            <li>The contract with Quickcalls is to provide the Client with a Contractor who is a competent engineer capable in the reasonable opinion of Quickcalls of providing the Services.</li>
            <li>
                The duties of Quickcalls are to send the Client’s details to the Contractor either by e-mail or by text which will include :-
                <ol>
                    <li>Job descriptions;</li>
                    <li>Client names;</li>
                    <li>Authorisation of first hour’s costs;</li>
                    <li>Client’s contact number and e-mail address;</li>
                    <li>Job and billing address; and</li>
                    <li>ETA.</li>
                </ol>
            </li>            
            <li>The Contractor is only authorised to carry out the first call by Quickcalls and is authorised up to the first hour’s labour. If the Contractor was to go over the first hour, further authorisation will be needed.</li>
            <li>It is the responsibility of the Contractor to attend within the time provided by Quickcalls as per the ETA given at the time of the e-mail or text.</li>
            <li>
                The Contractor is responsible for :-
                <ol>
                    <li>the work the Contractor provides; and</li>
                    <li>any damage caused by the Contractor resulting from the provision of the Services.</li>
                </ol>
            </li>
        
    </ol>
    </li>
    
    <li>
        BOOKING BY FORM/PHONE
        <ol>
            <li>If booking by form on the Quickcalls website we will attend the address provided and the deposit MUST be paid immediately after filling in the form. Once an online booking is complete, the Client MUST telephone Quickcalls to inform them of the booking so that the job can be allocated to one of the Contractors. An online booking may not be actioned if a follow up phone call is not made.</li>
            <li>If booking by phone, an employee of Qucikcalls should answer the phone by the greetings of the member of staff’s name and they will ask for the job postcode, nature of problem and will explain the rates and if the Client has heard the rates correctly. It is the responsibility of the Client to understand the terms before booking.</li>
            <li>It is the responsibility of the Client to acknowledge the terms, however Quickcalls will send a text that gives the Client a link to the terms aftercare and remind the Client what the Client has agreed to.</li>
            <li>Once a booking is submitted via our website we will receive a work order with details and we shall prepare the call out for you, however the booking is not complete until we receive notification of payment. Submition of work order form is will not complete your booking.</li>
            <li>30 Minutes shall begin from the time the payment notification is received by quickcalls. In the event that we cannot source an engineer for an emergency job within 30 minutes a full refund shall be provided to the client. This clause is only applicable in the case of emergency jobs.</li>
        </ol>
    </li>
    
    <li>
        STAFF & CONTRACTORS
        <ol>
            <li>Contractors are either employed by an alternative company or are self- employed.</li>
            <li>The standard text we send every Contractor is as follows: Job details; Client’s name; authorised Labour costs for first hour only plus materials; contact; address; post code; email address; ETA of address: By accepting this job, you acknowledge the Client above will be paying you at the end of the first call as explained in the terms.</li>
            <li>Quickcalls do not employee any engineers or workmen directly to carry out or attend call outs.</li>
            <li>Any works arranged or booked between the client and the enigineer is between the client and eningeer or the company they are employed by. Quickcalls will have no involvement in any works carried out after initial callout.</li>
            <li>The engineer is responsible for the first hour of labour only agrred between client and Quickcalls.</li>
            <li>All material used at any callout are the property of the engineer until payments are made in full.</li>
        </ol>
    </li>
    
    <li>
        GUARANTEE AND WARRANTY
        <ol>
            <li>Quickcalls do not guarantee the attendance of a Contractor within the time stated due to unforeseen circumstances.</li>
            <li>If the work that is carried out on a fixture that has not been maintained as stated in the manufacturer’s warranty, then the Contractor may not be able to guarantee the work completed.</li>
            <li>Some appliance and some fixtures require annual maintenance. If the Contractor was to work on a given job and to deem the work fair but annual maintenance had not been carried out the Contractor may not be able to guarantee the work completed.</li>
            <li>If the appliance is new, then a minimum warranty by the Contractor may be given as long as the original installation of the appliance does not affect the warranty.</li>
            <li>Any complaint concerning the service provided by the Contractor should be addressed to the Contractor directly.</li>
            <li>With any emergency that is causing damage to property then the objective is to make safe and get further authorisation from Quickcalls or the Contractor to commerce further work, but for the contract to go through EPHG, the Client should get direct authorisation for further work directly with Quickcalls and not the Contractor.</li>
        </ol>
    </li>
    
    <li>
        COPYRIGHT
        <ol>
            <li>All contents on the Quickcalls website belong to Quickcalls  and must not be used outside the website unless authorised by Quickcalls directly</li>
        </ol>
    </li>
    
    <li>
        PRIVACY POLICY
        <ol>
            <li>Any confidential or financial information belonging to a Client will be handled in accordance with the PCI – DSS standards and guidelines.</li>
            <li>The type of details shared between the Client, Quickcalls and Contractor are Job details, Client, Authorised costs, contact numbers, email addresses, Job address, meeting address, correspondence address and billing address.</li>
            <li>If the Client does not want Quickcalls to share certain details as in clause 10.2 this must be put in writing to quickcalls email and this will have to be confirmed back to the Client with confirmation that certain details will not be given to the engineer if deemed reasonable.</li>
            <li>Details explained in clause 10.2 will be shared with the aftercare team and will not be forwarded to any other third party.</li>
        </ol>
    </li>
    
    <li>
        INDEMNITY
        <ol>
            <li>Quickcalls is a company that provides Contractors as quickly as possible and works on behalf of the Client but cannot guarantee that Contractors will attend to Clients within the time stated due to unforeseen circumstances. Quickcalls do not provide compensation due to a contractor Contractor failing to turn up on time and the Contractor is responsible for their own work from the arrival to the completion of the work.</li>
        </ol>
    </li>
    
    <li>
        COMPLAINTS PROCEDURES
        <ol>
            <li>We hope to deal with all complaints in the most effective manor possible. All complaints must be reported and submitted via email to feedback@quickcalls.com. We will only consider emails received from emails used for booking the job in question.</li>
            <li>Complaints shall not be considered if made via social media platforms.</li>
            <li>
                Once a complaint is received it will be forwarded to the Contractor upon consent who will be asked to respond within 7-14 days. Quickcalls will use all reasonable endeavours to assist the Client in resolving any dispute with the Contractor and will provide such advice to the Client (such as details of the Trading Standards contact number 03454040506) as is reasonable in the circumstances.
                <ol>
                    <li>To consent to a complaint being forwarded to the contractor in question, we need permissions from the buyer or the authorised person in accordance to our privacy policy</li>
                </ol>
            </li>        
            <li>Please note that Quickcalls are not responsible for any complaints made between the Client and the Contractor in question, however, we are happy to assist in order to try to reach a resolution.</li>
            <li>If a complaint is being made, the wording 'complaint' MUST be mentioned in order for Quickcalls to address the complaint to a case reference.</li>
            <li>Once a complaint is raised, we will hold your complaint for a minimum of 6 years before deleting or disposing of.</li>
        </ol>
    </li>
    
</ol>
`

const contracterTandCs = `
<p>Prior to signing up to our database we advise all engineers to familiarise themselves with our TCs and the way we work. By taking on a job from Quickcalls the engineer understands the terms and conditions and what the call out may entail.</p>
<ol>
    <li>Contractor understands all term and conditions upon accepting a job.</li>
    <li>Contractor understands that they shall not receive any payments from Quickcalls for any jobs attended, works undertaken or materials used.</li>
    <li>Contractor understands that the agreed hourly rate and call out charge are to be paid to them directly by the client.</li>
    <li>Contract understands that they must accept payments from the client in the following methods; Cash, Debit card, Bank Transfer or Cheque.</li>
    <li>Contractor must provide an invoice of works to the client if so requested and may also be required by Quickcalls.</li>
    <li>Contractor agrees to only ask for payment at the end of the emergency call and not upon arrival.</li>
    <li>Contractor understands that the first 1 hour charge takes effect from the time of arrival to the job and not from the time the work order is received or whilst travelling the the job.</li>
    <li>Contractor understands that should any additional works be required after the initial hour, they must inform the client first and receive approval.</li>
    <li>Initial contact of a job may be made via phone call to the engineer from Quickcalls, this will be followed with a text containing details. Contractor must contact us if not received within 10 minutes of inital call.</li>
    <li>Should the client cancel a job whilst the contractor is en route, Quickcalls may pay upto £30 for inconvenience.</li>
    <li>Contractor must contact the client to inform of ETA and arrival.</li>
    <li>Contractor must record video or pictures of jobs where required and may be required to share it with Quickcalls</li>
    <li>Contractor must inform Quickcalls once enroute to the job and again once arrived – this must be done by either text or via a signed app which Quickcalls may provide.</li>
    <li>Contractor must provide all relevant certification of qualifications to Quickcalls.</li>
    <li>Contractor must have email and mobile contact number</li>
    <li>Contractors will take responsibility for all works carried out for clients.</li>
    <li>Any works arranged between the client who was introduced by Quickcalls and the contractor will be charged at 10% by Quickcalls</li>
    <li>If the contractors are not available to attend callouts any longer they must inform Quickcalls so to remove their details from the database.</li>
    <li>Contractors must behave in a professional manner with all clients at all time</li>
</ol>
`

const getTandCs = () => {
    return tandcs
}

const getContractorTandCs = () => {
    return contracterTandCs
}

module.exports = { getTandCs, getContractorTandCs }