import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';

import PostcodeLookup from './PostcodeLookup';
import { schema } from '../helpers/UserDetailsValidation';
import Modal from './Modal';
import {getTandCs} from '../services/tandcs-service'

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            additionalInfo: '',
            submitting: false,
            submitError: '',
            showPostcodeLookup: true,
            addressLine1: '',
            addressLine2: '',
            addressCity: '',
            addressPostcode: '',
            termsAndConditions: false,
            showTandCs: false,
            errors: []
        }
    }

    getUserDetails = () => {
        return _.pick(this.state, ['name', 'email', 'phone', 'additionalInfo', 'addressLine1',
            'addressLine2', 'addressCity', 'addressPostcode', 'termsAndConditions']);
    }

    validateUserDetails = () => {
        const row = this.getUserDetails();
        this.setState({ errors: [] });
        return schema.validate(row, { abortEarly: false }).then((value) => {
            return true;
        })
        .catch((err) => {
            err.inner.forEach(element => {
                const error = this.state.errors;
                error[element.path] = element.message;
                this.setState({ errors: error }, () => { });
            });
            return false;
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const valid = await this.validateUserDetails()
        if (valid) {
            this.setState({submitting: true})
            ReactGA.event({
                category: 'User Form',
                action: 'Form Submitted'
            });
            const row = this.getUserDetails();
            row.title = this.props.title;
            try {
                let response = await fetch("/.netlify/functions/email", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        subject: `${row.addressLine1}, ${row.addressPostcode}, ${row.name}`,
                        details: row
                    })
                })
                ReactGA.event({
                    category: 'User Form',
                    action: 'Form Submit Success'
                });
                if(!response.ok) {
                    throw new Error('Error sending email');
                }
                this.setState({submitting: false})
                this.props.onSubmit(this.getUserDetails());
            } catch (err) {
                ReactGA.event({
                    category: 'User Form',
                    action: 'Form Submit Failed'
                });
                this.setState({
                    submitting: false,
                    submitError: 'There was an error submitting your request. Please contact us.'
                });
            }
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onPostcodeLookup = async (val) => {
        this.setState({
            showPostcodeLookup: false,
            addressLine1: val.addressLine1,
            addressCity: val.city,
            addressPostcode: val.postcode
        });
        await this.validateUserDetails();
    }

    render() {
        const { errors, showTandCs, submitError } = this.state;
        const { title } = this.props;
        return (
            <Fragment>
                <Modal show={showTandCs} content={getTandCs()} title="Terms and Conditions" handleClose={() => this.setState({ showTandCs: false })} />
                <div className="content is-medium">
                    <h2>{title}</h2>
                    <p>
                        Please fill in your details below. Once submitted, we will begin sourcing a local expert to assist you.
                        Please note to complete your booking, you must complete the payment on the next screen. If we fail to confirm your booking within 30 minutes, we will refund you the FULL amount.
                    </p>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="field">
                        <div className="control">
                            <input className="input" name="name" type="text" onChange={this.handleChange} placeholder="Enter Your Full Name" />
                        </div>
                        {errors.name && <p className="help is-danger">{errors.name}</p>}
                    </div>
                    <div className="field">
                        <div className="control">
                            <input className="input" name="email" type="email" onChange={this.handleChange} placeholder="Email Address" />
                        </div>
                        {errors.email && <p className="help is-danger">{errors.email}</p>}
                    </div>
                    <div className="field">
                        <div className="control">
                            <input className="input" name="phone" type="text" onChange={this.handleChange} placeholder="Phone Number" />
                        </div>
                        {errors.phone && <p className="help is-danger">{errors.phone}</p>}
                    </div>
                    <div className="field">
                        <div className="control">
                            <textarea className="textarea" name="additionalInfo" onChange={this.handleChange} rows="4" placeholder="Enter any additional info" />
                        </div>
                        {errors.additionalInfo && <p className="help is-danger">{errors.additionalInfo}</p>}
                    </div>
                    {this.state.showPostcodeLookup ?
                        (<Fragment>
                            <PostcodeLookup onPostcodeLookup={this.onPostcodeLookup} />
                            {errors.addressLine1 && <p className="help is-danger">Please enter an your house number and postcode and press "Lookup"</p>}
                        </Fragment>) :
                        (<Fragment>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="addressLine1" type="text" value={this.state.addressLine1} onChange={this.handleChange} placeholder="Address Line 1" />
                                    {errors.addressLine1 && <p className="help is-danger">{errors.addressLine1}</p>}
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="addressLine2" type="text" onChange={this.handleChange} placeholder="Address Line 2" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="addressCity" type="text" value={this.state.addressCity} onChange={this.handleChange} placeholder="Town/City" />
                                </div>
                                {errors.addressCity && <p className="help is-danger">{errors.addressCity}</p>}
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" name="addressPostcode" type="text" value={this.state.addressPostcode} onChange={this.handleChange} placeholder="Postcode" />
                                </div>
                                {errors.addressPostcode && <p className="help is-danger">{errors.addressPostcode}</p>}
                            </div>
                            <div className="field">
                                <button className="button is-text" onClick={() => { this.setState({ showPostcodeLookup: true }) }}>Look up postcode again?</button>
                            </div>
                        </Fragment>)
                    }
                    <div className="field">
                        <label className="checkbox">
                            <input type="checkbox" onChange={this.handleChange} name="termsAndConditions"/> By clicking here you agree to the
                        </label>
                        <a onClick={() => {this.setState({ showTandCs: true })}}> terms and conditions</a>
                        {errors.termsAndConditions && <p className="help is-danger">{errors.termsAndConditions}</p>}
                    </div>
                    {submitError && <p className="help is-danger">{submitError}</p>}
                    <div className="field is-grouped is-grouped-right">
                        <div className="control">
                            <button type="submit" className={"button is-primary " + (this.state.submitting ? 'is-loading' : '')} disabled={this.state.submitting}>Submit</button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default UserForm